export function checkAdult() {
    const modal = document.getElementById('ageModal');
    const checkbox = document.getElementById('ageCheckbox');
    const confirmButton = document.getElementById('confirmButton');
    const body = document.body;

    if (localStorage.getItem('isAdult') === 'true') {
        modal.style.display = 'none'; 
        body.classList.remove('modal-open');
        return;
    }

    body.classList.add('modal-open');

    checkbox.addEventListener('change', () => {
        if (checkbox.checked) {
            confirmButton.disabled = false;
            confirmButton.classList.add('enabled');
        } else {
            confirmButton.disabled = true;
            confirmButton.classList.remove('enabled');
        }
    });

    
    confirmButton.addEventListener('click', () => {
        if (checkbox.checked) {
            localStorage.setItem('isAdult', 'true');
            modal.style.display = 'none';
            body.classList.remove('modal-open');
        }
    });
}
