export function setupDragEvents(gallery){
    let isDragging = false;  
    let startX;
    let scrollLeft;

    

    gallery.addEventListener('mousedown', (event) => {
        isDragging = true;
        gallery.classList.add('dragging');
        startX = event.pageX - gallery.offsetLeft;
        scrollLeft = gallery.scrollLeft;
    });
  

    gallery.addEventListener('mouseup', () => {
        isDragging = false;
        gallery.classList.remove('dragging');
    });
  

    gallery.addEventListener('mouseleave', () => {
        isDragging = false;
        gallery.classList.remove('dragging');
    });
    
    gallery.addEventListener('mousemove', (event) => {
        if (!isDragging) return;
        event.preventDefault();
        const x = event.pageX - gallery.offsetLeft;
        const walk = (x - startX) * 2;
        gallery.scrollLeft = scrollLeft - walk;
    });

}