import { translations } from './translet.js';
import { setupDragEvents } from './dragHandler.js';
import { navbar } from './navbar.js';
import { scrollMagic } from './scrollMagic.js';
import { switchLang } from './switchLang.js';
import { checkAdult } from './modalCheckAdult.js';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const gallery = document.querySelector('.collection-produc__container');

Fancybox.bind('[data-fancybox="gallery"]', {});

document.addEventListener('DOMContentLoaded', () => {
   checkAdult();
   scrollMagic();
   navbar();
   switchLang(translations);
   setupDragEvents(gallery);
});

