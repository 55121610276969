export function navbar(){
    const nav = document.querySelector('.navbardown');
    const menuLinks = document.querySelectorAll('.header__menu-link');
    const navBar = document.getElementById("navBar");


    nav.addEventListener('click', () => {
        navBar.classList.toggle('active');
    });

    
    menuLinks.forEach(link => {
        link.addEventListener('click', () => {
            if (navBar.classList.contains('active')) {
                navBar.classList.remove('active'); 
                
            }
        });
    });

    
    window.addEventListener("resize", () => {
        if (window.innerWidth >= 768) {
            navBar.classList.remove('active'); 
            
        }
    });

    document.addEventListener('click', (event) => {
        const isClickInsideNav = nav.contains(event.target);
        const isClickInsideMenu = navBar.contains(event.target);

        if (!isClickInsideNav && !isClickInsideMenu && navBar.className.includes('active')) {
            navBar.className = "header__menu";
        }
    });
}