export function switchLang(translations){
    const langImg = document.querySelector('.header__menu-lang');
    const iconPhone = document.querySelector('.icon-phone');
    const phoneNumber = document.querySelector('.footer__phone')
    
    let isImageOne = true;

    langImg.addEventListener('click', ()=>{
        if (isImageOne) {
            langImg.src = require('../image/lang/gb.svg');
            changeLanguage('eng');

        }else {
            langImg.src = require('../image/lang/ua.svg');
            changeLanguage('ua');
        }
        isImageOne = !isImageOne;
    });


    function changeLanguage(lang) {
        const elements = document.querySelectorAll('[data-lang]');

        elements.forEach(el => {
            const key = el.getAttribute('data-lang');
            el.textContent = translations[lang][key];
           
        });
    }
}

