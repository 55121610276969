

export const translations = {
    ua: {
        nav_main: "Головна",
        nav_ourus: "Про нас",
        nav_greatness: "Виробництво",
        nav_visual: "Дизайн",
        nav_taste: "Смак",
        nav_products: "Продукція",
        nav_contacts:"Контакти",
        //razdel 1
        hero__title: "Liberty Vodka: ідея, що надихає",
        hero__text: "Liberty Vodka — горілка преміум класу, яка символізує свободу, чистоту тавишуканість",
        hero__text2: "Це не просто напій, а новий досвід, який стає частиною Вашої свободи вибору",
        
        //razdel 2
        ourus_title: "Історія створення бренду",
        ourus_text1: "Liberty Vodka створена з метою поєднання традиційних рецептів виготовлення з сучасними технологіями",
        ourus_text2: "Бренд LIBERTOFF - символ свободи, якості та досконалості",
        ourus_text3: "Кожна крапля Liberty Vodka знаходить своє втілення в елегантній пляшці, яка є творінням мистецтва та підкреслює вишуканість продукту всередині.",
        //razdel 3
        greatness_title: "Мистецтво Виробництва",
        greatness_text1: "Для виготовлення Liberty Vodka використовується лише найкращі зернові спирти та спеціально підготовлена вода, що забезпечує неймовірну якість смаку",
        greatness_text2: "Контроль на кожному етапі виробництва гарантує високий результат якості",
        //razdel 4
        visual_title: "Пляшка як витвір мистецтва",
        visual_text1: "Пляшка Liberty Vodka — це справжній шедевр дизайну, що символізує свободу та інновації",
        visual_text2: "Унікальним елементом є ефект зміни кольору: при охолодженні пляшка стає синьою, що додає додаткового шарму та інтриги",
        visual_text3: "Дизайн пляшки розроблений таким чином, щоб підкреслювати преміум клас продукту, а також створювати яскравий візуальний акцент, який залишає незабутнє враження",
        //razdel 5
        taste_title: "Смак у кожній миті",
        taste_text1: "Liberty Vodka — це поєднання унікальної рецептури та неперевершеного смаку, що дозволяє насолоджуватися не лише чистим напоєм, а й створювати оригінальні коктейлі",
        taste_text2: "Вона підходить для будь-якої події, від елегантних вечірок до затишних зустрічей з друзями. Створює атмосферу розкоші і чарівності, додаючи особливого шарму кожному моменту",
        taste_text3: "LIBERTOFF — вибір для тих, хто шукає не лише смак, але й стиль у кожному моменті",
        //razdel 6
        produckt_title: "Наша продукція",
        //razdel 7
        contact_title: "Зв'язок з нами",
        title_qr: "Поділитися з друзями",

        
    },
    eng: {
        nav_main: "Home",
        nav_ourus: "About us",
        nav_greatness: "Greatness",
        nav_visual: "Visual",
        nav_taste: "Taste",
        nav_products: "Products",
        nav_contacts:"Contacts",
        //razdel 1
        hero__title: "Liberty Vodka: more than a drink",
        hero__text: "Liberty Vodka is a premium vodka that embodies freedom, purity, and sophistication",
        hero__text2: "It is not just a drink, but a new experience that becomes a part of your freedom of choice",
        
        //razdel 2
        ourus_title: "History of creation",
        ourus_text1: "Liberty Vodka is crafted with the aim of combining traditional production methods with modern technologies",
        ourus_text2: "The LIBERTOFF brand is a symbol of freedom, quality, and excellence",
        ourus_text3: "The unique bottle, which changes color, has become a visual representation of elegance and sophistication",
        //razdel 3
        greatness_title: "Greatness in every drop",
        greatness_text1: "Liberty Vodka is made using only the finest grain spirits and specially prepared water, ensuring an extraordinary taste quality",
        greatness_text2: "Strict control at every stage of production guarantees a high standard of quality",
        //razdel 4
        visual_title: "Visual language of the brand",
        visual_text1: "The Liberty Vodka bottle is a true design masterpiece, symbolizing freedom and innovation",
        visual_text2: "A unique feature is the color-changing effect: when chilled, the bottle turns blue, adding an extra touch of charm and intrigue",
        visual_text3: "The bottle's design is crafted to emphasize the premium quality of the product, while also creating a striking visual accent that leaves a lasting impression",
        //razdel 5
        taste_title: "Taste in every moment",
        taste_text1: "Liberty Vodka is a blend of a unique recipe and unparalleled taste, allowing you to enjoy not only the pure drink but also create original cocktails",
        taste_text2: "It is perfect for any occasion, from elegant parties to cozy gatherings with friends. It creates an atmosphere of luxury and charm, adding a special touch to every moment",
        taste_text3: "LIBERTOFF is the choice for those who seek not only flavor but also style in every moment",
        //razdel 6
        produckt_title: "Our products",
        //razdel 7
        contact_title: "Contact us",
        title_qr: "Share with friends",

        
        

    }
};